import React from "react";

const PrivacyPolicy = () => {
  return (
    <div className="max-w-4xl mx-auto px-4 py-8 bg-white">
      {/* Header */}
      <div className="mb-8 text-center border-b border-gray-200 pb-8">
        <h1 className="text-4xl font-bold text-gray-900 mb-2">Privacy Policy</h1>
        <p className="text-gray-600">Ingena Technology Private Limited (Operating as MINUUTES)</p>
        <p className="text-sm text-gray-500 mt-2">Last Updated: November 24, 2024</p>
      </div>

      {/* Main Content */}
      <div className="space-y-8">
        {/* Introduction */}
        <section>
          <h2 className="text-2xl font-semibold text-gray-900 mb-4">1. Introduction</h2>
          <div className="bg-blue-50 border-l-4 border-blue-500 p-4">
            <p className="text-blue-700">
              At MINUUTES, we take your privacy seriously. This Privacy Policy explains how we collect, use, disclose, and safeguard your
              information when you use our consulting infrastructure platform.
            </p>
          </div>
        </section>

        {/* Information We Collect */}
        <section>
          <h2 className="text-2xl font-semibold text-gray-900 mb-4">2. Information We Collect</h2>
          <div className="space-y-4">
            <div className="border border-gray-200 rounded-lg p-4">
              <h3 className="font-semibold text-gray-900 mb-2">2.1 Personal Information</h3>
              <ul className="list-disc pl-6 space-y-2 text-gray-700">
                <li>Name and contact information</li>
                <li>Professional background and expertise</li>
                <li>Payment and billing information</li>
                <li>Profile pictures and biographical information</li>
                <li>Communication preferences</li>
              </ul>
            </div>

            <div className="border border-gray-200 rounded-lg p-4">
              <h3 className="font-semibold text-gray-900 mb-2">2.2 Usage Data</h3>
              <ul className="list-disc pl-6 space-y-2 text-gray-700">
                <li>Login and activity timestamps</li>
                <li>Device and browser information</li>
                <li>IP addresses and location data</li>
                <li>Consultation history and duration</li>
                <li>Feature usage patterns</li>
              </ul>
            </div>
          </div>
        </section>

        {/* How We Use Your Information */}
        <section>
          <h2 className="text-2xl font-semibold text-gray-900 mb-4">3. How We Use Your Information</h2>
          <div className="bg-gray-50 p-6 rounded-lg space-y-4">
            <div>
              <h3 className="font-semibold text-gray-900 mb-2">3.1 Primary Uses</h3>
              <ul className="list-disc pl-6 space-y-2 text-gray-700">
                <li>Facilitating consulting sessions</li>
                <li>Processing payments and transactions</li>
                <li>Providing customer support</li>
                <li>Improving our services</li>
                <li>Sending service updates and notifications</li>
              </ul>
            </div>

            <div>
              <h3 className="font-semibold text-gray-900 mb-2">3.2 Communication</h3>
              <ul className="list-disc pl-6 space-y-2 text-gray-700">
                <li>Service-related notifications</li>
                <li>Platform updates and changes</li>
                <li>Marketing communications (with consent)</li>
                <li>Security alerts</li>
              </ul>
            </div>
          </div>
        </section>

        {/* Data Protection */}
        <section>
          <h2 className="text-2xl font-semibold text-gray-900 mb-4">4. Data Protection</h2>
          <div className="bg-green-50 border-l-4 border-green-500 p-4 mb-4">
            <p className="text-gray-700">We implement robust security measures to protect your information, including:</p>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div className="bg-gray-50 p-4 rounded-lg">
              <h3 className="font-semibold text-gray-900 mb-2">Technical Measures</h3>
              <ul className="list-disc pl-6 space-y-2 text-gray-700">
                <li>SSL/TLS encryption</li>
                <li>Secure data storage</li>
                <li>Regular security audits</li>
                <li>Access controls</li>
              </ul>
            </div>
            <div className="bg-gray-50 p-4 rounded-lg">
              <h3 className="font-semibold text-gray-900 mb-2">Organizational Measures</h3>
              <ul className="list-disc pl-6 space-y-2 text-gray-700">
                <li>Employee training</li>
                <li>Data access policies</li>
                <li>Incident response plans</li>
                <li>Regular compliance reviews</li>
              </ul>
            </div>
          </div>
        </section>

        {/* Data Sharing */}
        <section>
          <h2 className="text-2xl font-semibold text-gray-900 mb-4">5. Data Sharing</h2>
          <div className="border border-gray-200 rounded-lg p-6 space-y-4">
            <p className="text-gray-700">We may share your information with:</p>
            <ul className="list-disc pl-6 space-y-2 text-gray-700">
              <li>Service providers (payment processors, hosting services)</li>
              <li>Professional advisers as required by law</li>
              <li>Law enforcement when legally required</li>
              <li>Other users as needed for platform functionality</li>
            </ul>
            <div className="bg-yellow-50 border-l-4 border-yellow-500 p-4 mt-4">
              <p className="text-gray-700">We never sell your personal information to third parties.</p>
            </div>
          </div>
        </section>

        {/* User Rights */}
        <section>
          <h2 className="text-2xl font-semibold text-gray-900 mb-4">6. Your Rights</h2>
          <div className="bg-gray-50 p-6 rounded-lg">
            <p className="text-gray-700 mb-4">You have the right to:</p>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div>
                <ul className="list-disc pl-6 space-y-2 text-gray-700">
                  <li>Access your personal data</li>
                  <li>Correct inaccurate data</li>
                  <li>Request data deletion</li>
                  <li>Object to data processing</li>
                </ul>
              </div>
              <div>
                <ul className="list-disc pl-6 space-y-2 text-gray-700">
                  <li>Withdraw consent</li>
                  <li>Request data portability</li>
                  <li>Lodge complaints</li>
                  <li>Restrict processing</li>
                </ul>
              </div>
            </div>
          </div>
        </section>

        {/* Cookies */}
        <section>
          <h2 className="text-2xl font-semibold text-gray-900 mb-4">7. Cookies</h2>
          <div className="border border-gray-200 rounded-lg p-6">
            <p className="text-gray-700 mb-4">We use cookies for:</p>
            <ul className="list-disc pl-6 space-y-2 text-gray-700">
              <li>Essential platform functionality</li>
              <li>Performance monitoring</li>
              <li>User experience improvement</li>
              <li>Security purposes</li>
            </ul>
          </div>
        </section>

        {/* Contact Information */}
        <section>
          <h2 className="text-2xl font-semibold text-gray-900 mb-4">8. Contact Us</h2>
          <div className="bg-gray-50 p-6 rounded-lg">
            <p className="text-gray-700 mb-4">For privacy-related inquiries, please contact our Data Protection Officer:</p>
            <div className="space-y-3">
              <div className="flex items-center">
                <svg className="h-5 w-5 text-gray-500 mr-3" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
                  />
                </svg>
                <span className="text-gray-700">business@minuutes.com</span>
              </div>
              <div className="flex items-center">
                <svg className="h-5 w-5 text-gray-500 mr-3" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"
                  />
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 11a3 3 0 11-6 0 3 3 0 016 0z" />
                </svg>
                <span className="text-gray-700">
                  Ingena Technology Private Limited
                  <br />
                  Patna, Bihar, India
                </span>
              </div>
            </div>
          </div>
        </section>

        {/* Footer */}
        <div className="mt-12 pt-8 border-t border-gray-200">
          <p className="text-sm text-gray-500 text-center">This privacy policy is subject to change. Last updated: November 24, 2024</p>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
