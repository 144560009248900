import React, { useState, useEffect } from "react";
import {
  CircleDot,
  Copy,
  Code,
  Check,
  XSquareIcon,
  LinkedinIcon,
  Facebook,
  Eye,
  Loader2,
  ArrowLeft,
  ExternalLink,
  Calendar,
  Clock,
  Shield,
  Star,
  XIcon,
  MapPin,
  Edit2,
} from "lucide-react";
import { motion, AnimatePresence } from "framer-motion";
import Confetti from "react-confetti"; // Import confetti library

const LandingPage = () => {
  const [step, setStep] = useState(0);
  const [profile, setProfile] = useState(null);
  const [selectedTime, setSelectedTime] = useState(null);
  const [rate, setRate] = useState("");
  const [loading, setLoading] = useState(false);
  const [showCode, setShowCode] = useState(false);
  const [copied, setCopied] = useState(false);
  const [generatingLink, setGeneratingLink] = useState(false);
  // Add to existing states
  const [showBooking, setShowBooking] = useState(false);
  const [bookingStep, setBookingStep] = useState(0);
  const [paymentLoading, setPaymentLoading] = useState(false);
  const [bookingComplete, setBookingComplete] = useState(false);
  const [userEmail, setUserEmail] = useState("");
  // Add to existing states
  const [cardNumber, setCardNumber] = useState("");
  const [cardExpiry, setCardExpiry] = useState("");
  const [cardCVC, setCardCVC] = useState("");
  const [autofilling, setAutofilling] = useState(false);
  const [activePlatform, setActivePlatform] = useState(null); // Track active platform // Verification Step
  const [showCelebration, setShowCelebration] = useState(false); // Control celebration modal
  const [defaultTimeSlots, setDefaultTimeSlots] = useState(["10:00 AM", "2:00 PM", "4:00 PM", "7:00 PM"]); // Default slots
  const [newTimeSlot, setNewTimeSlot] = useState(""); // For adding custom time slots
  const [timeZone, setTimeZone] = useState("PST"); // Default time zone
  const [userProfile, setUserProfile] = useState(null);
  const [editingBio, setEditingBio] = useState(false);

  const mockProfile = {
    name: "Sam Altman",
    handle: "@sama",
    bio: "Building the future of AI. YC President (2014-19). Now OpenAI.",
    image: "https://im.indiatimes.in/content/2023/Jun/FyExfSUWIAAVy1-_64820bb160bac.jpeg",
    followers: "1.2M",
    rating: 4.9,
    reviews: 128,
  };

  // Enhanced animations
  const pageTransition = {
    initial: { opacity: 0, y: 20 },
    animate: { opacity: 1, y: 0 },
    exit: { opacity: 0, y: -20 },
    transition: { duration: 0.4 },
  };

  const importProfile = async () => {
    setLoading(true);
    await new Promise((resolve) => setTimeout(resolve, 1500));
    setProfile(mockProfile);
    setLoading(false);
    setStep(1);
  };

  const generateLink = async () => {
    setGeneratingLink(true);
    await new Promise((resolve) => setTimeout(resolve, 2000));
    setGeneratingLink(false);
    setStep(2);
  };

  const copyToClipboard = (isCode) => {
    if (!userProfile || !userProfile.handle) {
      console.error("User profile or handle is missing.");
      return;
    }

    const textToCopy = isCode
      ? `<script src="https://minuutes.com/embed/${userProfile.handle}"></script>`
      : `minuutes.com/${userProfile.handle.replace("@", "")}`;

    navigator.clipboard.writeText(textToCopy);

    setCopied(true);
    setShowCelebration(true);

    // setTimeout(() => {
    //   setCopied(false);
    //   setShowCelebration(false);
    // }, 2000);
  };

  const closeCelebration = () => setShowCelebration(false); // Close celebration modal

  return (
    <div className="min-h-screen bg-black text-white overflow-x-hidden">
      {/* Enhanced Background */}
      <div className="fixed inset-0">
        <div className="absolute inset-0 bg-[radial-gradient(circle_at_top_right,_var(--tw-gradient-stops))] from-purple-900/20 via-black to-black" />
        <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-[1000px] h-[1000px] bg-purple-600/10 rounded-full blur-3xl" />
      </div>

      {/* Full-Screen Celebration */}

      {/* Full-Screen Celebration */}
      {showCelebration && (
        <div className="fixed inset-0 z-50 bg-black/80 flex items-center justify-center">
          <Confetti width={window.innerWidth} height={window.innerHeight} />
          <div className="relative bg-gradient-to-br from-purple-600 to-pink-600 p-12 rounded-3xl shadow-xl text-center text-white max-w-lg mx-auto">
            <h2 className="text-4xl font-bold mb-4">🎉 Congratulations!</h2>
            <p className="text-lg mb-6">Your link is ready. You are now ready to start accepting bookings!</p>
            <div className="grid grid-cols-1 sm:grid-cols-1 gap-4">
              <button
                onClick={() => {
                  closeCelebration();
                  setStep(3); // Navigate to the final experience preview
                }}
                className="bg-gradient-to-r from-purple-500 to-pink-500 text-white font-medium py-3 px-6 rounded-xl hover:opacity-90 transition"
              >
                Preview Final Page
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Premium Nav */}
      <nav className="fixed w-full z-50 px-12 py-8 backdrop-blur-sm bg-black/10">
        <div className="text-4xl font-black tracking-tight flex items-center">
          MINU
          <span className="bg-gradient-to-r from-purple-400 to-pink-400 text-transparent bg-clip-text">UTES</span>
          <span className="text-pink-400 text-[2.5rem] -translate-y-2 ml-0.5 font-serif tracking-tighter">"</span>
        </div>
      </nav>
      {/* Progress Bar */}
      {step > 0 && step < 4 && (
        <div className="fixed top-0 left-0 w-full h-1 bg-white/[0.03] z-50">
          <motion.div
            className="h-full bg-gradient-to-r from-purple-500 to-pink-500"
            initial={{ width: "0%" }}
            animate={{ width: `${(step / 3) * 100}%` }}
            transition={{ duration: 0.5 }}
          />
        </div>
      )}

      <div className="relative min-h-screen">
        {/* Landing Page */}
        {/* Step 0 - Google Login Landing */}
        {step === 0 && (
          <motion.div {...pageTransition} className="pt-40 px-12 pb-32">
            <div className="max-w-4xl mx-auto space-y-20">
              {/* Hero Section */}
              <div>
                <motion.h1
                  initial={{ opacity: 0, y: 30 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.8 }}
                  className="text-[96px] font-black leading-[1.1] tracking-tight mb-6"
                >
                  {/* From Pixels to Real */}
                  Let your internet presence
                  <motion.span
                    initial={{ opacity: 0, scale: 0.8 }}
                    animate={{ opacity: 1, scale: 1 }}
                    transition={{ duration: 0.6, delay: 0.4 }}
                    className="bg-gradient-to-r from-purple-400 via-pink-400 to-purple-400 text-transparent bg-clip-text"
                  >
                    {" "}
                    pay you
                  </motion.span>
                </motion.h1>
                {/* 
                <motion.p
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.6, delay: 0.2 }}
                  className="text-xl text-white/80"
                >
                  Turn your internet presence into real connections (and income).
                </motion.p> */}
              </div>

              {/* Magical Moment Section */}
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.3 }}
                className="relative group"
              >
                <div className="absolute -inset-0.5 bg-gradient-to-r from-purple-500/20 to-pink-500/20 rounded-3xl blur opacity-75" />
                <div className="relative bg-black/40 backdrop-blur-xl border border-white/10 rounded-3xl p-8 space-y-4">
                  <h2 className="text-2xl font-bold">Imagine This:</h2>
                  <p className="text-xl text-white/80">
                    Someone just discovered your insightful tweet, binge-watched your latest video, or bookmarked your Reddit advice and
                    thinks:
                    <br />
                    <span className="text-xl text-purple-400 italic">"Wait... Can i actually talk to this person?"</span>
                  </p>
                  <p className="text-lg text-purple-400 font-medium italic">Yes. That's what MINUUTES makes possible.</p>
                </div>
              </motion.div>

              {/* Drop Your Link Section */}
              <motion.div initial={{ opacity: 0, y: 20 }} animate={{ opacity: 1, y: 0 }} transition={{ delay: 0.4 }} className="space-y-6">
                <h2 className="text-2xl font-bold">The Magic of Minuutes:</h2>
                <div className="grid grid-cols-2 gap-6">
                  {[
                    {
                      platform: "On Twitter",
                      content: "Got value from my thread?\n→  Let’s chat: minuutes.com/you",
                      gradient: "from-blue-500/20 to-blue-600/20",
                      icon: "twitter",
                    },
                    {
                      platform: "On YouTube:",
                      content: "Loved this video?\n→ Let’s dive deeper: minuutes.com/you",
                      gradient: "from-red-500/20 to-red-600/20",
                      icon: "youtube",
                    },
                    {
                      platform: "On Reddit:",
                      content: "Need more tips like these?\n→ Book a call: minuutes.com/you",
                      gradient: "from-orange-500/20 to-orange-600/20",
                      icon: "reddit",
                    },
                    {
                      platform: "In Your Instagram Bio:",
                      content: "Curious about my journey? → Let’s connect: minuutes.com/you",
                      gradient: "from-purple-500/20 to-pink-500/20",
                      icon: "instagram",
                    },
                  ].map((item, index) => (
                    <motion.div
                      key={index}
                      initial={{ opacity: 0, y: 20 }}
                      animate={{ opacity: 1, y: 0 }}
                      transition={{ delay: 0.5 + index * 0.1 }}
                      className="relative group"
                    >
                      <div
                        className={`absolute inset-0 bg-gradient-to-br ${item.gradient} rounded-2xl blur-xl opacity-50 group-hover:opacity-100 transition duration-500`}
                      />
                      <div className="relative bg-black/40 backdrop-blur-sm border border-white/10 rounded-2xl p-6">
                        <h3 className="text-lg font-semibold mb-3">{item.platform}</h3>
                        <p className="text-white/80 whitespace-pre-line">{item.content}</p>
                      </div>
                    </motion.div>
                  ))}
                </div>
              </motion.div>

              {/* Success Stories */}
              <motion.div initial={{ opacity: 0, y: 20 }} animate={{ opacity: 1, y: 0 }} transition={{ delay: 0.6 }} className="space-y-6">
                <h2 className="text-2xl font-bold">Real Stories from Creators Like You:</h2>
                <div className="grid grid-cols-2 gap-6">
                  {[
                    "I shared my Minuutes link after a coding thread — made $500 in a day.",
                    "Added it to my travel photos — earned $200 planning dream vacations.",
                    "Used it in my recipe video captions — hosted virtual cooking classes with fans!",
                    "Shared it in a tweet reply — got booked for 1:1 strategy sessions",
                  ].map((story, index) => (
                    <motion.div
                      key={index}
                      initial={{ opacity: 0, x: -20 }}
                      animate={{ opacity: 1, x: 0 }}
                      transition={{ delay: 0.7 + index * 0.1 }}
                      className="bg-white/5 rounded-xl p-6 border border-white/10 hover:border-purple-500/50 transition-colors"
                    >
                      <p className="text-white/80">"{story}"</p>
                    </motion.div>
                  ))}
                </div>
              </motion.div>

              {/* The Internet Just Changed Forever */}
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.8 }}
                className="relative group"
              >
                <div className="absolute -inset-0.5 bg-gradient-to-r from-purple-500/20 to-pink-500/20 rounded-3xl blur opacity-75" />
                <div className="relative bg-black/40 backdrop-blur-xl border border-white/10 rounded-3xl p-8 space-y-6">
                  <h2 className="text-2xl font-bold">The Internet Just Changed Forever:</h2>
                  <p className="text-lg text-white/80">Right now, someone out there is:</p>
                  <ul className="space-y-3">
                    {[
                      "Reading your helpful answer (Add your link!)",
                      "Watching your video (Drop it in description!)",
                      "Saving your thread (Put it in replies!)",
                      "Loving your content (Add to bio!)",
                      "Wishing they could talk to you (Give them your link!)",
                    ].map((item, index) => (
                      <motion.li
                        key={index}
                        initial={{ opacity: 0, x: -20 }}
                        animate={{ opacity: 1, x: 0 }}
                        transition={{ delay: 0.9 + index * 0.1 }}
                        className="flex items-center gap-3 text-white/80"
                      >
                        <div className="w-1.5 h-1.5 bg-purple-400 rounded-full" />
                        {item}
                      </motion.li>
                    ))}
                  </ul>
                  <div className="pt-4 text-lg text-white/80">
                    <p>Make it easy for them to take the next step →Talking to you.</p>
                    {/* <p>Not just pixels on a screen.</p> */}
                  </div>
                </div>
              </motion.div>

              {/* One Link Changes Everything */}
              <motion.div initial={{ opacity: 0, y: 20 }} animate={{ opacity: 1, y: 0 }} transition={{ delay: 1.1 }} className="space-y-6">
                <h2 className="text-2xl font-bold">How It Works:</h2>
                <div className="grid grid-cols-2 gap-6">
                  {[
                    "Sign up with Google",
                    "Create your personal link: minuutes.com/yourname",
                    "Share it across your online world.",
                    "Turn your followers into conversations.",
                  ].map((step, index) => (
                    <motion.div
                      key={index}
                      initial={{ opacity: 0, y: 20 }}
                      animate={{ opacity: 1, y: 0 }}
                      transition={{ delay: 1.2 + index * 0.1 }}
                      className="bg-white/5 rounded-xl p-6 border border-white/10"
                    >
                      <div className="flex items-center gap-4">
                        <div className="w-8 h-8 bg-purple-500/20 rounded-full flex items-center justify-center text-purple-400 font-bold">
                          {index + 1}
                        </div>
                        <p className="text-white/80">{step}</p>
                      </div>
                    </motion.div>
                  ))}
                </div>
              </motion.div>

              {/* Features Section */}
              <motion.div initial={{ opacity: 0, y: 20 }} animate={{ opacity: 1, y: 0 }} transition={{ delay: 1.3 }} className="space-y-8">
                <div className="space-y-6">
                  <h2 className="text-2xl font-bold">You Get Everything You Need:</h2>
                  <div className="grid grid-cols-2 gap-4">
                    {[
                      "Your personal booking page",
                      "Professional video calls",
                      "Instant payments",
                      "Auto scheduling",
                      "Your chosen rates",
                    ].map((feature, index) => (
                      <motion.div
                        key={index}
                        initial={{ opacity: 0, x: -20 }}
                        animate={{ opacity: 1, x: 0 }}
                        transition={{ delay: 1.4 + index * 0.1 }}
                        className="flex items-center gap-3"
                      >
                        <Check className="w-5 h-5 text-purple-400" />
                        <span className="text-white/80">{feature}</span>
                      </motion.div>
                    ))}
                  </div>
                </div>

                <div className="bg-gradient-to-r from-purple-500/20 to-pink-500/20 rounded-xl p-6">
                  <h3 className="text-lg font-semibold mb-4">First 100 creators get:</h3>
                  <div className="space-y-3">
                    {["Featured status", "Priority support", "Early access"].map((perk, index) => (
                      <motion.div
                        key={index}
                        initial={{ opacity: 0, x: -20 }}
                        animate={{ opacity: 1, x: 0 }}
                        transition={{ delay: 1.5 + index * 0.1 }}
                        className="flex items-center gap-3"
                      >
                        <Star className="w-5 h-5 text-purple-400" fill="currentColor" />
                        <span className="text-white/80">{perk}</span>
                      </motion.div>
                    ))}
                  </div>
                </div>
              </motion.div>

              {/* Sign In Button */}
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ delay: 1.6 }}
                id="signup-section"
                className="relative group"
              >
                <div className="absolute -inset-0.5 bg-gradient-to-r from-purple-500 to-pink-500 rounded-3xl blur opacity-30 group-hover:opacity-100 transition duration-1000" />
                <div className="relative bg-black/60 backdrop-blur-xl border border-white/[0.05] rounded-3xl p-12">
                  <motion.button
                    onClick={async () => {
                      setLoading(true);
                      await new Promise((resolve) => setTimeout(resolve, 1500));
                      setUserProfile({
                        name: "Sam Altman",
                        firstName: "Sam",
                        photo: "https://example.com/photo.jpg",
                        location: "San Francisco, CA",
                        email: "sam@example.com",
                        bio: "Building the future of AI.",
                        handle: "@sama",
                        rating: 4.8,
                        reviews: 981,
                      });
                      setLoading(false);
                      setStep(1);
                    }}
                    disabled={loading}
                    className="w-full bg-white hover:bg-gray-50 text-gray-900 rounded-2xl py-6 text-xl font-medium flex items-center justify-center gap-3 transition-all"
                  >
                    {loading ? (
                      <Loader2 className="w-6 h-6 animate-spin" />
                    ) : (
                      <>
                        <svg className="w-6 h-6" viewBox="0 0 24 24">
                          <path
                            fill="currentColor"
                            d="M22.56 12.25c0-.78-.07-1.53-.2-2.25H12v4.26h5.92c-.26 1.37-1.04 2.53-2.21 3.31v2.77h3.57c2.08-1.92 3.28-4.74 3.28-8.09z"
                          />
                          <path
                            fill="currentColor"
                            d="M12 23c2.97 0 5.46-.98 7.28-2.66l-3.57-2.77c-.98.66-2.23 1.06-3.71 1.06-2.86 0-5.29-1.93-6.16-4.53H2.18v2.84C3.99 20.53 7.7 23 12 23z"
                          />
                          <path
                            fill="currentColor"
                            d="M5.8414.09c-.22-.66-.35-1.36-.35-2.09s.13-1.43.35-2.09V7.07H2.18C1.43 8.55 1 10.22 1 12s.43 3.45 1.18 4.93l2.85-2.22.81-.62z"
                          />
                          <path
                            fill="currentColor"
                            d="M12 5.38c1.62 0 3.06.56 4.21 1.64l3.15-3.15C17.45 2.09 14.97 1 12 1 7.7 1 3.99 3.47 2.18 7.07l3.66 2.84c.87-2.6 3.3-4.53 6.16-4.53z"
                          />
                        </svg>
                        Continue with Google
                      </>
                    )}
                  </motion.button>

                  {/* Closing Messages */}
                  <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ delay: 1.7 }} className="mt-12 space-y-8">
                    <div className="space-y-4 text-center">
                      <p className="text-lg text-white/80 italic">
                        Stop being just pixels on a screen.
                        <br />
                        Your Audience Wants to Connect with You.
                      </p>
                    </div>

                    {/* P.S. Section */}
                    <motion.div
                      initial={{ opacity: 0, y: 10 }}
                      animate={{ opacity: 1, y: 0 }}
                      transition={{ delay: 1.8 }}
                      className="bg-gradient-to-r from-purple-500/10 to-pink-500/10 rounded-xl p-6 text-center"
                    >
                      <p className="text-lg text-white/80">
                        P.S. Someone's discovering you right now.
                        <br />
                        <span className="text-purple-400">Make sure your Minuutes link is there when they do.</span>
                      </p>
                    </motion.div>
                  </motion.div>
                </div>
              </motion.div>
            </div>
          </motion.div>
        )}

        {step === 1 && userProfile && (
          <motion.div {...pageTransition} className="pt-40 px-12">
            <div className="max-w-2xl mx-auto">
              <div className="bg-black/60 backdrop-blur-xl border border-white/[0.05] rounded-3xl p-12 space-y-12">
                {/* Enhanced Profile Section */}
                <motion.div
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  className="flex items-center gap-8 bg-gradient-to-br from-purple-500/30 to-pink-500/10 rounded-3xl p-6 shadow-lg"
                >
                  <div className="relative">
                    <div className="absolute -inset-1 bg-gradient-to-r from-purple-500 to-pink-500 rounded-full blur opacity-50" />
                    <img
                      src={userProfile.photo}
                      alt={userProfile.name}
                      className="relative w-24 h-24 rounded-full border-2 border-white/10"
                    />
                  </div>
                  <div className="flex-1">
                    <div className="flex items-center justify-between">
                      <h2 className="text-3xl font-bold mb-1">Welcome, {userProfile.firstName}! 👋</h2>
                      <button
                        onClick={() => setEditingBio(!editingBio)}
                        className="bg-white/5 hover:bg-white/10 p-2 rounded-xl text-white/40 hover:text-white transition-all"
                      >
                        <Edit2 className="w-5 h-5" />
                      </button>
                    </div>
                    <div className="text-white/60 mb-2 flex items-center gap-2">
                      <MapPin className="w-4 h-4" />
                      {userProfile.location}
                    </div>
                    {editingBio ? (
                      <textarea
                        defaultValue={userProfile.bio}
                        className="w-full bg-black/40 rounded-lg p-2 text-sm border border-white/10 focus:border-purple-500 mt-2"
                        placeholder="Tell us about your expertise..."
                      />
                    ) : (
                      <p className="text-white/40 text-sm">{userProfile.bio || "Tell us about your expertise..."}</p>
                    )}
                  </div>
                </motion.div>

                {/* Time Zone Selection */}
                <motion.div
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ delay: 0.2 }}
                  className="space-y-4"
                >
                  <h3 className="text-2xl font-bold">Select Your Time Zone</h3>
                  <select
                    onChange={(e) => setTimeZone(e.target.value)}
                    className="w-full bg-black/40 rounded-2xl pl-6 pr-6 py-4 text-xl border border-white/10 focus:border-purple-500"
                  >
                    <option value="GMT" selected>
                      PST
                    </option>
                    <option value="EST">EST</option>
                    <option value="CST">CST</option>
                    <option value="PST">GMT</option>
                    <option value="IST">IST</option>
                    <option value="AEST">AEST</option>
                  </select>
                </motion.div>

                {/* Default Time Slot Selection */}
                <motion.div
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ delay: 0.3 }}
                  className="space-y-4"
                >
                  <h3 className="text-2xl font-bold">Select a Time Slot</h3>
                  <div className="grid grid-cols-2 gap-4">
                    {defaultTimeSlots.map((time, index) => (
                      <button
                        key={index}
                        onClick={() => setSelectedTime(time)}
                        className={`relative group p-6 rounded-2xl text-center transition-all overflow-hidden ${
                          selectedTime === time ? "bg-white text-black" : "bg-white/5 hover:bg-white/10"
                        }`}
                      >
                        {time}
                        {selectedTime === time && <motion.div className="absolute inset-0 bg-white -z-10" layoutId="selectedTime" />}
                      </button>
                    ))}
                  </div>

                  {/* Add/Update Default Times */}
                  <div className="flex gap-4 mt-4">
                    <input
                      type="time"
                      onChange={(e) => setNewTimeSlot(e.target.value)}
                      value={newTimeSlot}
                      className="flex-1 bg-black/40 rounded-xl px-4 py-3 text-lg border border-white/10 focus:border-purple-500"
                      placeholder="Add new time"
                    />
                    <button
                      onClick={() => {
                        if (newTimeSlot) {
                          setDefaultTimeSlots([...defaultTimeSlots, newTimeSlot]);
                          setNewTimeSlot("");
                        }
                      }}
                      className="px-6 py-3 bg-purple-500 text-white rounded-xl font-medium hover:bg-purple-600"
                    >
                      Add Time
                    </button>
                  </div>
                </motion.div>

                {/* Rate Selection */}
                <motion.div
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ delay: 0.4 }}
                  className="space-y-4"
                >
                  <h3 className="text-2xl font-bold">Set Your Rate</h3>
                  <div className="grid grid-cols-2 gap-4">
                    {[250, 500, 750, 1000].map((rateOption, index) => (
                      <button
                        key={index}
                        onClick={() => setRate(rateOption)}
                        className={`p-6 rounded-2xl text-center transition-all ${
                          rate === rateOption ? "bg-white text-black" : "bg-white/5 hover:bg-white/10"
                        }`}
                      >
                        ${rateOption}
                      </button>
                    ))}
                  </div>
                  {/* Custom Rate Input */}
                  <div className="relative">
                    <input
                      type="number"
                      value={rate}
                      onChange={(e) => setRate(e.target.value)}
                      placeholder="Enter custom rate"
                      className="relative w-full bg-black/40 rounded-2xl pl-12 pr-6 py-6 text-xl border border-white/10 focus:border-purple-500"
                    />
                    <div className="absolute left-6 top-1/2 -translate-y-1/2 text-white/40">$</div>
                  </div>
                </motion.div>

                {/* Confirm Button */}
                <motion.button
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ delay: 0.5 }}
                  onClick={() => {
                    setLoading(true); // Set loading state
                    setTimeout(() => {
                      setLoading(false); // Reset loading state after 2-3 seconds
                      setStep(2); // Transition to the next step
                    }, 2500); // 2.5 seconds loading time
                  }}
                  disabled={loading || !selectedTime || !rate} // Disable if loading, time, or rate is not set
                  className={`w-full rounded-2xl py-6 text-xl font-medium flex items-center justify-center gap-2 transition-all ${
                    selectedTime && rate && !loading ? "bg-white text-black hover:bg-white/90" : "bg-white/10 text-white/40"
                  }`}
                >
                  {loading ? (
                    <>
                      <Loader2 className="w-5 h-5 animate-spin" />
                      Loading...
                    </>
                  ) : (
                    "Generate Consulting Link"
                  )}
                </motion.button>
              </div>
            </div>
          </motion.div>
        )}
        {/* New Verification Step */}
        {step === 1.5 && (
          <motion.div {...pageTransition} className="pt-40 px-12">
            <div className="max-w-2xl mx-auto">
              <div className="bg-black/60 backdrop-blur-xl border border-white/[0.05] rounded-3xl p-12 space-y-12">
                <motion.div initial={{ opacity: 0, y: 20 }} animate={{ opacity: 1, y: 0 }} transition={{ delay: 0.2 }}>
                  <h2 className="text-2xl font-bold mb-8">Verify You Are {profile.handle}</h2>
                  <p className="text-white/60 mb-8">
                    Please connect one of your social accounts to verify your identity. We respect your privacy and do not store your login
                    details.
                  </p>
                </motion.div>

                <div className="space-y-4">
                  {[
                    {
                      name: "Twitter",
                      icon: (
                        <svg xmlns="http://www.w3.org/2000/svg" className="w-6 h-6 text-white" fill="currentColor" viewBox="0 0 24 24">
                          <path d="M24 4.557c-.883.392-1.83.656-2.825.775a4.927 4.927 0 002.163-2.723 9.865 9.865 0 01-3.127 1.196 4.916 4.916 0 00-8.379 4.482 13.934 13.934 0 01-10.112-5.135 4.922 4.922 0 001.523 6.568 4.902 4.902 0 01-2.228-.616c-.054 2.281 1.581 4.415 3.946 4.89a4.93 4.93 0 01-2.224.085 4.918 4.918 0 004.6 3.417A9.867 9.867 0 010 21.54a13.905 13.905 0 007.548 2.212c9.057 0 14.01-7.514 14.01-14.01 0-.213-.005-.425-.014-.637a10.025 10.025 0 002.457-2.553z" />
                        </svg>
                      ),
                      color: "bg-gradient-to-r from-blue-400 to-blue-600",
                      hoverColor: "hover:from-blue-500 hover:to-blue-700",
                    },
                    {
                      name: "LinkedIn",
                      icon: (
                        <svg xmlns="http://www.w3.org/2000/svg" className="w-6 h-6 text-white" fill="currentColor" viewBox="0 0 24 24">
                          <path d="M22.225 0H1.771C.792 0 0 .774 0 1.729v20.542C0 23.229.792 24 1.771 24h20.451c.981 0 1.777-.774 1.777-1.729V1.729C24 .774 23.206 0 22.225 0zm-15.8 20.452H3.539V8.982h2.884v11.47zm-1.425-13.18c-.938 0-1.71-.762-1.71-1.692 0-.936.777-1.698 1.71-1.698.934 0 1.709.762 1.709 1.698 0 .93-.777 1.692-1.71 1.692zM20.452 20.452h-2.886v-5.946c0-1.416-.028-3.242-1.975-3.242-1.974 0-2.275 1.544-2.275 3.136v6.051h-2.886V8.982h2.773v1.566h.04c.387-.732 1.33-1.503 2.738-1.503 2.928 0 3.467 1.928 3.467 4.436v6.97z" />
                        </svg>
                      ),
                      color: "bg-gradient-to-r from-blue-600 to-blue-800",
                      hoverColor: "hover:from-blue-700 hover:to-blue-900",
                    },
                    {
                      name: "Facebook",
                      icon: (
                        <svg xmlns="http://www.w3.org/2000/svg" className="w-6 h-6 text-white" fill="currentColor" viewBox="0 0 24 24">
                          <path d="M22.675 0h-21.35C.595 0 0 .589 0 1.318v21.363c0 .73.595 1.319 1.325 1.319h11.48V14.69H9.768V11.04h3.037V8.487c0-3.007 1.796-4.665 4.547-4.665 1.318 0 2.691.235 2.691.235v2.996h-1.515c-1.493 0-1.957.929-1.957 1.878v2.11h3.328l-.532 3.648h-2.796v9.31h5.478c.73 0 1.325-.589 1.325-1.318V1.318c0-.73-.595-1.318-1.325-1.318z" />
                        </svg>
                      ),
                      color: "bg-gradient-to-r from-blue-500 to-indigo-500",
                      hoverColor: "hover:from-blue-600 hover:to-indigo-600",
                    },
                  ].map((platform, index) => (
                    <motion.button
                      key={platform.name}
                      whileHover={{ scale: 1.03 }}
                      whileTap={{ scale: 0.97 }}
                      onClick={async () => {
                        setLoading(true);
                        await new Promise((resolve) => setTimeout(resolve, 1500)); // Simulate login
                        setLoading(false);
                        setStep(2); // Proceed to next step
                      }}
                      disabled={loading}
                      className={`${platform.color} ${platform.hoverColor} w-full flex items-center justify-between p-6 rounded-2xl shadow-md transition-all`}
                    >
                      <div className="flex items-center gap-4">
                        <div className="w-10 h-10 bg-black/20 rounded-full flex items-center justify-center">{platform.icon}</div>
                        <span className="text-lg font-medium text-white">{`Continue with ${platform.name}`}</span>
                      </div>
                      {loading ? (
                        <Loader2 className="w-6 h-6 text-white animate-spin" />
                      ) : (
                        <ArrowLeft className="w-6 h-6 rotate-180 text-white opacity-60 group-hover:opacity-100 transition-opacity" />
                      )}
                    </motion.button>
                  ))}
                </div>

                <div className="mt-8 p-4 bg-purple-500/10 rounded-xl flex items-start gap-3">
                  <Shield className="w-5 h-5 text-purple-400 mt-0.5" />
                  <p className="text-sm text-white/60">
                    Your information is secure and will only be used for verification purposes. We never share your data with third parties.
                  </p>
                </div>
              </div>
            </div>
          </motion.div>
        )}
        {/* New Verification Step */}

        {/* Link Generation Page */}
        {step === 2 && (
          <motion.div {...pageTransition} className="pt-40 px-12">
            <div className="max-w-3xl mx-auto space-y-10">
              {/* Title Section */}
              <div className="bg-gradient-to-br from-purple-600 to-pink-600 text-white rounded-3xl p-12 shadow-xl">
                <motion.h2
                  initial={{ opacity: 0, y: 10 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ delay: 0.2 }}
                  className="text-4xl font-bold mb-4"
                >
                  🎉 Your Link is Ready!
                </motion.h2>
                <motion.p
                  initial={{ opacity: 0, y: 10 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ delay: 0.3 }}
                  className="text-white/80 text-lg"
                >
                  Share this link anywhere to start accepting bookings instantly.
                </motion.p>
              </div>

              {/* Link Display */}
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.4 }}
                className="relative group bg-black/60 backdrop-blur-xl rounded-3xl p-8 font-mono text-xl text-center text-white shadow-lg"
              >
                <div className="absolute -inset-0.5 bg-gradient-to-r from-purple-500 to-pink-500 rounded-3xl blur-lg opacity-30 group-hover:opacity-100 transition duration-500" />
                <div className="relative">minuutes.com/{userProfile?.handle.replace("@", "")}</div>
              </motion.div>

              {/* Action Buttons */}
              <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ delay: 0.5 }} className="grid grid-cols-2 gap-6">
                {/* Copy Link Button */}
                <motion.button
                  whileHover={{ scale: 1.03 }}
                  whileTap={{ scale: 0.97 }}
                  onClick={() => copyToClipboard(false)}
                  className="flex items-center justify-center gap-2 bg-gradient-to-r from-purple-500 to-pink-500 text-white font-medium text-lg py-4 px-6 rounded-2xl shadow-lg hover:opacity-90 transition-all"
                >
                  <Copy className="w-6 h-6" />
                  Copy Link
                </motion.button>

                {/* Show Embed Code Button */}
                <motion.button
                  whileHover={{ scale: 1.03 }}
                  whileTap={{ scale: 0.97 }}
                  onClick={() => {
                    setShowCode(!showCode);
                    copyToClipboard(true);
                  }}
                  className="flex items-center justify-center gap-2 bg-gradient-to-r from-gray-700 to-gray-800 text-white font-medium text-lg py-4 px-6 rounded-2xl shadow-lg hover:opacity-90 transition-all"
                >
                  <Code className="w-6 h-6" />
                  Get Embed Code
                </motion.button>
              </motion.div>

              {/* Embed Code Section */}
              <AnimatePresence>
                {showCode && (
                  <motion.div
                    initial={{ height: 0, opacity: 0 }}
                    animate={{ height: "auto", opacity: 1 }}
                    exit={{ height: 0, opacity: 0 }}
                    className="overflow-hidden bg-black/70 backdrop-blur-xl rounded-3xl p-8 text-white font-mono text-sm shadow-lg"
                  >
                    {`<script src="https://minuutes.com/embed/${userProfile.handle}"></script>`}
                  </motion.div>
                )}
              </AnimatePresence>
            </div>
          </motion.div>
        )}
        {step === 3 && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            className="fixed inset-0 bg-gradient-to-br from-black via-gray-900 to-black z-50 flex flex-col"
          >
            {/* Gradient Background */}
            <div className="absolute inset-0 overflow-hidden">
              <div className="absolute top-0 left-0 w-[800px] h-[600px] bg-purple-600/30 rounded-full blur-[200px]" />
              <div className="absolute bottom-0 right-0 w-[800px] h-[600px] bg-pink-600/30 rounded-full blur-[200px]" />
              <div className="absolute top-1/3 left-1/3 w-[500px] h-[500px] bg-gradient-to-r from-purple-700 to-pink-700 opacity-20 blur-[150px]" />
            </div>

            {/* Header */}
            <header className="relative px-12 py-6 flex justify-between items-center z-10">
              <div className="text-4xl font-extrabold text-white flex items-center tracking-tight">
                MINUUTES
                <CircleDot className="text-pink-400 ml-2 w-6 h-6 animate-pulse" />
              </div>
              <button onClick={() => setStep(2)} className="text-white/80 hover:text-white flex items-center gap-2 transition-all">
                <ArrowLeft className="w-5 h-5" />
                <span className="text-lg font-medium">Back</span>
              </button>
            </header>

            {/* Main Content */}
            <main className="relative flex-1 flex flex-col items-center justify-center px-8 z-10">
              {/* Preview Banner */}
              <div className="absolute top-8 right-8 px-5 py-1.5 bg-gradient-to-r from-purple-500 to-pink-500 text-white text-sm font-semibold uppercase rounded-full shadow-md">
                <ExternalLink className="inline-block w-5 h-5 align-middle mr-2" />
                Preview Mode
              </div>

              {/* Booking Section */}
              <div className="w-full max-w-5xl bg-gradient-to-br from-gray-800 via-black to-gray-900 rounded-3xl p-12 shadow-xl border border-white/10 grid grid-cols-1 md:grid-cols-2 gap-12">
                {/* Left Column - Expert Profile */}
                <div className="space-y-8">
                  {/* Profile Header */}
                  <div className="flex items-center gap-6">
                    <div className="relative">
                      <div className="absolute -inset-1 bg-gradient-to-r from-purple-500 to-pink-500 rounded-full blur-md opacity-50" />
                      <img
                        src={userProfile?.photo}
                        className="relative w-28 h-28 rounded-full border-4 border-white/10 object-cover shadow-lg"
                        alt={userProfile?.name}
                      />
                    </div>
                    <div>
                      <h1 className="text-4xl font-bold text-white">{userProfile?.name}</h1>
                      <div className="flex items-center gap-4 mt-2 text-white/60">
                        {userProfile?.handle}
                        <span className="w-1 h-1 bg-white/40 rounded-full" />
                        <div className="flex items-center gap-1">
                          <Star className="w-4 h-4 text-yellow-400" fill="currentColor" />
                          <span>
                            {userProfile.rating} ({userProfile.reviews} reviews)
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* Bio */}
                  <p className="text-lg text-white/80">{userProfile?.bio}</p>

                  {/* Availability */}
                  <div className="text-white text-base font-medium bg-gradient-to-r from-purple-600 to-pink-600 px-4 py-2 rounded-full shadow-md w-fit">
                    <Clock className="inline-block w-5 h-5 align-middle text-white mr-2" />
                    Available in the next 1 hour
                  </div>
                </div>

                {/* Right Column - Booking */}
                <div className="flex flex-col items-center bg-gradient-to-br from-purple-500/10 to-pink-500/10 rounded-3xl p-8 shadow-xl">
                  {/* Highlighted Slot */}
                  <div className="bg-gradient-to-br from-purple-600 to-pink-600 rounded-2xl p-6 shadow-lg text-center mb-6">
                    <h2 className="text-lg font-medium text-white/80">Today's Slot</h2>
                    <div className="text-5xl font-bold text-white mt-2">{selectedTime}</div>
                    <p className="text-white/60 mt-1">30-minute private consultation</p>
                  </div>

                  {/* Pricing */}
                  <h2 className="text-5xl font-bold text-white mt-4">${rate}</h2>

                  {/* Call-to-Action */}
                  <motion.button
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                    onClick={() => setShowBooking(true)}
                    className="mt-6 w-full bg-gradient-to-r from-purple-500 to-pink-500 text-white text-lg font-semibold py-4 rounded-xl shadow-md hover:opacity-90"
                  >
                    Book a <span className="text-green-500">Minuute</span> with Me
                  </motion.button>

                  {/* Immediate Booking Notice */}
                  <p className="text-sm text-white/60 mt-4">
                    <Check className="inline-block w-5 h-5 mr-2 align-middle text-green-400" />
                    Video call link will be sent instantly after booking
                  </p>
                </div>
              </div>
            </main>
          </motion.div>
        )}

        {/* Booking Modal */}
        {step === 3 && showBooking && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            className="fixed inset-0 z-[60] bg-black/90 backdrop-blur-xl flex items-center justify-center p-8"
          >
            <motion.div initial={{ scale: 0.95, opacity: 0 }} animate={{ scale: 1, opacity: 1 }} className="w-full max-w-2xl">
              {bookingStep === 0 && (
                <div className="bg-gradient-to-b from-white/[0.03] to-transparent backdrop-blur-2xl rounded-3xl border border-white/[0.05] p-12 space-y-8">
                  <div className="space-y-2">
                    <h2 className="text-3xl font-bold">Confirm Your Call</h2>
                    <p className="text-white/60">30-minute call with {userProfile?.name}</p>
                  </div>

                  <div className="grid grid-cols-2 gap-6">
                    <div className="bg-white/[0.02] rounded-2xl p-6">
                      <Calendar className="w-5 h-5 text-purple-400 mb-3" />
                      <div className="text-white/40 text-sm mb-1">Today</div>
                      <div className="text-xl font-medium">{selectedTime}</div>
                    </div>
                    <div className="bg-white/[0.02] rounded-2xl p-6">
                      <Shield className="w-5 h-5 text-purple-400 mb-3" />
                      <div className="text-white/40 text-sm mb-1">Total</div>
                      <div className="text-xl font-medium">${rate}</div>
                    </div>
                  </div>

                  <div className="space-y-4">
                    <input
                      type="email"
                      value={userEmail}
                      onChange={(e) => setUserEmail(e.target.value)}
                      placeholder="Your email"
                      className="w-full bg-black/40 rounded-xl px-6 py-4 text-lg border border-white/10 focus:border-purple-500"
                    />

                    <textarea
                      placeholder="Any specific topics you'd like to discuss?"
                      className="w-full bg-black/40 rounded-xl px-6 py-4 text-lg border border-white/10 focus:border-purple-500 h-32"
                    />
                  </div>

                  <div className="flex gap-4">
                    <button
                      onClick={() => setShowBooking(false)}
                      className="flex-1 bg-white/5 hover:bg-white/10 rounded-xl py-4 font-medium transition-colors"
                    >
                      Cancel
                    </button>
                    <button
                      onClick={() => setBookingStep(1)}
                      className="flex-1 bg-white text-black rounded-xl py-4 font-medium hover:bg-white/90 transition-colors"
                    >
                      Continue to Payment
                    </button>
                  </div>
                </div>
              )}

              {bookingStep === 1 && (
                <div className="bg-gradient-to-b from-white/[0.03] to-transparent backdrop-blur-2xl rounded-3xl border border-white/[0.05] p-12 space-y-8">
                  <div className="space-y-2">
                    <h2 className="text-3xl font-bold">Payment</h2>
                    <p className="text-white/60">Secure payment powered by Stripe</p>
                  </div>

                  <div className="bg-white/[0.02] rounded-2xl p-6 space-y-4">
                    <div className="grid grid-cols-2 gap-4">
                      <div className="space-y-2">
                        <div className="text-white/40 text-sm">Expert</div>
                        <div className="font-medium">{userProfile?.name}</div>
                      </div>
                      <div className="space-y-2">
                        <div className="text-white/40 text-sm">Time</div>
                        <div className="font-medium">{selectedTime}</div>
                      </div>
                    </div>
                    <div className="border-t border-white/[0.05] pt-4">
                      <div className="flex justify-between items-center">
                        <div className="text-white/40">Total</div>
                        <div className="text-xl font-bold">${rate}</div>
                      </div>
                    </div>
                  </div>

                  {/* Mock Payment Form */}
                  <div className="space-y-4">
                    <div className="relative">
                      <input
                        type="text"
                        value={cardNumber}
                        onChange={(e) => setCardNumber(e.target.value)}
                        placeholder="Card number"
                        className="w-full bg-black/40 rounded-xl px-6 py-4 text-lg border border-white/10 focus:border-purple-500"
                      />
                      <button
                        onClick={() => {
                          setAutofilling(true);
                          // Demo card number animation
                          const demoCard = "4242 4242 4242 4242";
                          let i = 0;
                          const interval = setInterval(() => {
                            setCardNumber(demoCard.slice(0, i));
                            i++;
                            if (i > demoCard.length) {
                              clearInterval(interval);
                              setCardExpiry("12/24");
                              setTimeout(() => {
                                setCardCVC("123");
                                setAutofilling(false);
                              }, 300);
                            }
                          }, 50);
                        }}
                        className="absolute right-4 top-1/2 -translate-y-1/2 text-xs text-white/40 hover:text-white/60"
                      >
                        {autofilling ? <Loader2 className="w-4 h-4 animate-spin" /> : "Demo Card"}
                      </button>
                    </div>

                    <div className="grid grid-cols-2 gap-4">
                      <input
                        type="text"
                        value={cardExpiry}
                        onChange={(e) => setCardExpiry(e.target.value)}
                        placeholder="MM/YY"
                        className="bg-black/40 rounded-xl px-6 py-4 text-lg border border-white/10 focus:border-purple-500"
                      />
                      <input
                        type="text"
                        value={cardCVC}
                        onChange={(e) => setCardCVC(e.target.value)}
                        placeholder="CVC"
                        className="bg-black/40 rounded-xl px-6 py-4 text-lg border border-white/10 focus:border-purple-500"
                      />
                    </div>
                  </div>

                  {/* Add a fancy gradient demo card display */}
                  {(cardNumber || cardExpiry || cardCVC) && (
                    <motion.div
                      initial={{ opacity: 0, y: 20 }}
                      animate={{ opacity: 1, y: 0 }}
                      className="relative group h-48 rounded-2xl overflow-hidden"
                    >
                      <div className="absolute inset-0 bg-gradient-to-br from-purple-500/20 via-pink-500/20 to-blue-500/20" />
                      <div className="absolute inset-0 backdrop-blur-xl" />
                      <div className="relative p-8 h-full flex flex-col justify-between">
                        <div className="flex justify-between items-start">
                          <div className="space-y-4">
                            <div className="font-mono text-lg">{cardNumber || "•••• •••• •••• ••••"}</div>
                            <div className="flex gap-4 text-sm">
                              <div>
                                <div className="text-white/40 text-xs mb-1">Expires</div>
                                <div>{cardExpiry || "MM/YY"}</div>
                              </div>
                              <div>
                                <div className="text-white/40 text-xs mb-1">CVC</div>
                                <div>{cardCVC || "•••"}</div>
                              </div>
                            </div>
                          </div>
                          <motion.div
                            animate={{
                              rotateY: autofilling ? 180 : 0,
                              scale: autofilling ? 1.1 : 1,
                            }}
                            transition={{ duration: 0.5 }}
                          >
                            <Shield className="w-8 h-8 text-white/40" />
                          </motion.div>
                        </div>

                        <div className="text-sm text-white/40">Demo Card</div>
                      </div>
                    </motion.div>
                  )}

                  <button
                    onClick={() => {
                      setPaymentLoading(true);
                      setTimeout(() => {
                        setPaymentLoading(false);
                        setBookingStep(2);
                      }, 2000);
                    }}
                    className="w-full bg-white text-black rounded-xl py-4 font-medium hover:bg-white/90 transition-colors flex items-center justify-center gap-2"
                  >
                    {paymentLoading ? (
                      <>
                        <Loader2 className="w-5 h-5 animate-spin" />
                        Processing...
                      </>
                    ) : (
                      "Pay Now"
                    )}
                  </button>
                </div>
              )}

              {bookingStep === 2 && (
                <motion.div
                  initial={{ scale: 0.9, opacity: 0 }}
                  animate={{ scale: 1, opacity: 1 }}
                  className="bg-gradient-to-b from-white/[0.03] to-transparent backdrop-blur-2xl rounded-3xl border border-white/[0.05] p-12 text-center"
                >
                  <div className="w-20 h-20 bg-green-500 rounded-full mx-auto mb-8 flex items-center justify-center">
                    <Check className="w-10 h-10 text-white" />
                  </div>

                  <h2 className="text-3xl font-bold mb-4">You're All Set!</h2>
                  <p className="text-white/60 mb-8">
                    Your call with {userProfile?.name} is confirmed for today at {selectedTime}
                  </p>

                  <div className="bg-white/[0.02] rounded-2xl p-6 mb-8">
                    <div className="text-sm text-white/40 mb-2">Calendar Invite Sent To</div>
                    <div className="font-mono">{userEmail}</div>
                  </div>

                  <button
                    onClick={() => {
                      setShowBooking(false);
                      setBookingStep(0);
                    }}
                    className="w-full bg-white text-black rounded-xl py-4 font-medium hover:bg-white/90 transition-colors"
                  >
                    Done
                  </button>
                </motion.div>
              )}
            </motion.div>
          </motion.div>
        )}
      </div>

      {/* Pricing */}
      {/* Pricing Section */}
      <motion.div
        // initial={{ opacity: 0, y: 40 }}
        // animate={{ opacity: 1, y: 0 }}
        // transition={{ delay: 0.8 }}
        className="max-w-5xl mx-auto py-20 px-12"
      >
        {/* Header */}
        <div className="text-center mb-16 space-y-4">
          <h2 className="text-5xl font-bold text-white">Pricing & Fees</h2>
          <p className="text-white text-xl">Start accepting consultations in minutes</p>
        </div>

        {/* Main Pricing Card */}
        <div className="relative">
          <div className="absolute -inset-0.5 bg-gradient-to-r from-purple-500 to-pink-500 rounded-3xl blur opacity-75" />
          <div className="relative bg-black/60 backdrop-blur-xl border border-white/[0.05] rounded-3xl overflow-hidden">
            {/* Top Half */}
            <div className="p-12 border-b border-white/10">
              <div className="grid md:grid-cols-2 gap-12">
                <div>
                  <h3 className="text-2xl font-semibold mb-4">Per-consultation pricing</h3>
                  <div className="flex items-baseline gap-2 mb-6">
                    <span className="text-5xl font-bold">5%</span>
                    <span className="text-white/60">per successful calls</span>
                  </div>
                  <div className="bg-gradient-to-r from-purple-500/10 to-pink-500/10 rounded-xl p-4">
                    <p className="text-sm text-white/80">First 5 calls completely free. No platform fees.</p>
                  </div>
                </div>
                <div className="space-y-4">
                  <div className="flex items-start gap-3">
                    <Check className="w-5 h-5 text-purple-400 mt-1" />
                    <div>
                      <h4 className="font-medium">Pay as you grow</h4>
                      <p className="text-white/60 text-sm">Only pay when you earn</p>
                    </div>
                  </div>
                  <div className="flex items-start gap-3">
                    <Check className="w-5 h-5 text-purple-400 mt-1" />
                    <div>
                      <h4 className="font-medium">Weekly Payouts</h4>
                      <p className="text-white/60 text-sm">Get paid directly to your bank account</p>
                    </div>
                  </div>
                  <div className="flex items-start gap-3">
                    <Check className="w-5 h-5 text-purple-400 mt-1" />
                    <div>
                      <h4 className="font-medium">No hidden fees</h4>
                      <p className="text-white/60 text-sm">Transparent pricing, no surprises</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Start CTA */}
        <div className="text-center mt-12">
          <button className="bg-gradient-to-r from-purple-500 to-pink-500 text-white font-medium px-8 py-4 rounded-xl hover:opacity-90 transition-opacity">
            Start Accepting Consultations
          </button>
          <p className="text-white/40 text-sm mt-4">No credit card required</p>
        </div>
      </motion.div>

      {/* FAQ */}

      {/* FAQ Section */}
      <motion.div
        initial={{ opacity: 0, y: 40 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.8 }}
        className="max-w-5xl mx-auto py-20 px-12"
      >
        {/* Header */}
        <div className="text-center mb-16 space-y-4">
          <div className="inline-flex items-center gap-2 px-4 py-2 bg-purple-500/10 rounded-full text-purple-400 text-sm font-medium mb-4">
            <CircleDot className="w-4 h-4" />
            Common Questions
          </div>
          <h2 className="text-5xl font-bold">Frequently Asked Questions</h2>
          <p className="text-white/60 text-xl">Everything you need to know about MINUUTES</p>
        </div>

        {/* FAQ Grid */}
        <div className="grid gap-6">
          {[
            {
              q: "What makes MINUUTES different?",
              a: "MINUUTES transforms every piece of your content into a gateway for paid sessions. When someone finds value in your tweet, post, answer, or video, they can instantly book time with you—right then and there.",
            },
            {
              q: "How quickly can I start monetizing?",
              a: "As fast as you can share your link. Your existing content starts driving bookings immediately - we've seen creators make their first $500 within 24 hours of sharing their link.",
            },
            {
              q: "Where should I add my MINUUTES link?",
              a: "Anywhere you're already providing value.  Every piece of content becomes a potential booking opportunity.",
            },
            {
              q: "How do I manage my availability?",
              a: "Set your schedule (like 3pm and 6pm daily) when you sign up, then just toggle 'available' in our Chrome extension whenever you're ready to take calls.",
            },

            {
              q: "Are there any hidden fees?",
              a: "Never. We charge a flat 5% fee on successful consultations. Your first 5 calls are completely free. No monthly fees, no setup costs, no hidden charges.",
            },

            {
              q: "How do I get started?",
              a: "Sign in with Google, set your rate, get your link, share it with your next piece of content. The whole setup takes 2 minutes. The sooner you start, the sooner your content starts making you money.",
            },
          ].map((faq, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.1 * index }}
              className="relative group"
            >
              <div className="absolute -inset-0.5 bg-gradient-to-r from-purple-500/20 to-pink-500/20 rounded-2xl blur opacity-0 group-hover:opacity-100 transition duration-500" />
              <div className="relative bg-black/60 backdrop-blur-xl border border-white/[0.05] rounded-2xl p-8">
                <h3 className="text-xl font-semibold mb-4">{faq.q}</h3>
                <p className="text-white/60 leading-relaxed">{faq.a}</p>
              </div>
            </motion.div>
          ))}
        </div>

        {/* Bottom CTA */}
        <div className="mt-16 text-center">
          <div className="relative group inline-block">
            <div className="absolute -inset-0.5 bg-gradient-to-r from-purple-500 to-pink-500 rounded-xl blur opacity-75 group-hover:opacity-100 transition duration-500" />
            <button
              onClick={() => {
                document.getElementById("signup-section").scrollIntoView({
                  behavior: "smooth",
                  block: "center",
                });
              }}
              className="relative bg-black px-8 py-4 rounded-xl"
            >
              <span className="relative bg-gradient-to-r from-purple-400 to-pink-400 text-transparent bg-clip-text font-medium">
                Ready to monetize your presence?
              </span>
            </button>
          </div>
        </div>
      </motion.div>

      {/* Footer */}
      <div className="fixed bottom-0 w-full bg-black/60 backdrop-blur-sm border-t border-white/[0.05] px-12 py-4">
        <div className="max-w-7xl mx-auto flex items-center justify-between">
          <div className="flex items-center gap-6 text-sm text-white/60">
            <a href="/terms" className="hover:text-white transition-colors">
              Terms
            </a>
            <a href="/privacy" className="hover:text-white transition-colors">
              Privacy
            </a>
            <a href="/shipping" className="hover:text-white transition-colors">
              Shipping
            </a>
            <a href="/refund" className="hover:text-white transition-colors">
              Refund Policy
            </a>
            <a href="/contact" className="hover:text-white transition-colors">
              Contact
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LandingPage;
