import React from "react";

const TermsAndConditions = () => {
  return (
    <div className="max-w-4xl mx-auto px-4 py-8 bg-white">
      {/* Header Section */}
      <div className="mb-8 text-center border-b border-gray-200 pb-8">
        <h1 className="text-4xl font-bold text-gray-900 mb-2">Terms and Conditions</h1>
        <p className="text-gray-600">Ingena Technology Private Limited (Operating as MINUUTES)</p>
        <p className="text-sm text-gray-500 mt-2">Last Updated: November 24, 2024</p>
      </div>

      {/* Main Content */}
      <div className="space-y-8">
        {/* Introduction */}
        <section>
          <h2 className="text-2xl font-semibold text-gray-900 mb-4">1. Introduction</h2>
          <div className="bg-blue-50 border-l-4 border-blue-500 p-4 mb-6">
            <p className="text-blue-700">
              Welcome to MINUUTES. By accessing our platform, you agree to these terms and conditions in full. If you disagree with any part
              of these terms, you must not use our platform.
            </p>
          </div>
        </section>

        {/* Definitions */}
        <section>
          <h2 className="text-2xl font-semibold text-gray-900 mb-4">2. Definitions</h2>
          <div className="bg-gray-50 p-6 rounded-lg space-y-4">
            <div>
              <h3 className="font-semibold text-gray-900">Platform</h3>
              <p className="text-gray-700">Refers to MINUUTES consulting infrastructure and all related services.</p>
            </div>
            <div>
              <h3 className="font-semibold text-gray-900">User</h3>
              <p className="text-gray-700">Any individual or entity using the MINUUTES platform.</p>
            </div>
            <div>
              <h3 className="font-semibold text-gray-900">Consultant</h3>
              <p className="text-gray-700">Professionals providing consulting services through MINUUTES.</p>
            </div>
            <div>
              <h3 className="font-semibold text-gray-900">Client</h3>
              <p className="text-gray-700">Individuals or entities seeking consulting services through MINUUTES.</p>
            </div>
          </div>
        </section>

        {/* Service Terms */}
        <section>
          <h2 className="text-2xl font-semibold text-gray-900 mb-4">3. Service Terms</h2>
          <div className="space-y-4">
            <div className="border border-gray-200 rounded-lg p-4">
              <h3 className="font-semibold text-gray-900 mb-2">3.1 Platform Usage</h3>
              <ul className="list-disc pl-6 space-y-2 text-gray-700">
                <li>Users must be at least 18 years old</li>
                <li>Valid payment method required for transactions</li>
                <li>Accurate information must be provided during registration</li>
                <li>Account sharing is strictly prohibited</li>
              </ul>
            </div>

            <div className="border border-gray-200 rounded-lg p-4">
              <h3 className="font-semibold text-gray-900 mb-2">3.2 Consultant Obligations</h3>
              <ul className="list-disc pl-6 space-y-2 text-gray-700">
                <li>Maintain professional standards</li>
                <li>Provide accurate expertise information</li>
                <li>Adhere to scheduled consultation times</li>
                <li>Maintain confidentiality of client information</li>
              </ul>
            </div>

            <div className="border border-gray-200 rounded-lg p-4">
              <h3 className="font-semibold text-gray-900 mb-2">3.3 Client Obligations</h3>
              <ul className="list-disc pl-6 space-y-2 text-gray-700">
                <li>Provide accurate booking information</li>
                <li>Attend scheduled consultations on time</li>
                <li>Pay for services as per agreed terms</li>
                <li>Respect consultant intellectual property rights</li>
              </ul>
            </div>
          </div>
        </section>

        {/* Payment Terms */}
        <section>
          <h2 className="text-2xl font-semibold text-gray-900 mb-4">4. Payment Terms</h2>
          <div className="bg-gray-50 p-6 rounded-lg space-y-4">
            <div>
              <h3 className="font-semibold text-gray-900 mb-2">4.1 Pricing</h3>
              <p className="text-gray-700">All fees are listed in the applicable currency before consultation booking.</p>
            </div>

            <div>
              <h3 className="font-semibold text-gray-900 mb-2">4.2 Payment Processing</h3>
              <ul className="list-disc pl-6 space-y-2 text-gray-700">
                <li>Secure payment processing through authorized providers</li>
                <li>Platform fee of 5% on all transactions</li>
                <li>Payments processed immediately upon booking</li>
              </ul>
            </div>

            <div>
              <h3 className="font-semibold text-gray-900 mb-2">4.3 Refunds</h3>
              <ul className="list-disc pl-6 space-y-2 text-gray-700">
                <li>Full refund for cancellations 24 hours before scheduled time</li>
                <li>Partial refund for technical issues during consultation</li>
                <li>No refund for missed appointments</li>
              </ul>
            </div>
          </div>
        </section>

        {/* Intellectual Property */}
        <section>
          <h2 className="text-2xl font-semibold text-gray-900 mb-4">5. Intellectual Property</h2>
          <div className="bg-yellow-50 border-l-4 border-yellow-500 p-4 mb-6">
            <p className="text-gray-700">
              All content, features, and functionality of the MINUUTES platform are owned by Ingena Technology Private Limited and are
              protected by international copyright, trademark, and other intellectual property laws.
            </p>
          </div>
          <div className="space-y-4">
            <p className="text-gray-700">Users agree not to:</p>
            <ul className="list-disc pl-6 space-y-2 text-gray-700">
              <li>Copy or reproduce platform features</li>
              <li>Modify or create derivative works</li>
              <li>Distribute or transmit platform content</li>
              <li>Attempt to decompile or reverse engineer any portion</li>
            </ul>
          </div>
        </section>

        {/* Privacy and Data Protection */}
        <section>
          <h2 className="text-2xl font-semibold text-gray-900 mb-4">6. Privacy and Data Protection</h2>
          <div className="bg-gray-50 p-6 rounded-lg space-y-4">
            <p className="text-gray-700">
              Our Privacy Policy, which details how we collect, use, and protect your data, is incorporated into these Terms and Conditions.
            </p>
            <div className="space-y-2">
              <h3 className="font-semibold text-gray-900">We commit to:</h3>
              <ul className="list-disc pl-6 space-y-2 text-gray-700">
                <li>Protect user data with industry-standard security measures</li>
                <li>Never sell personal information to third parties</li>
                <li>Comply with applicable data protection regulations</li>
                <li>Provide users control over their data</li>
              </ul>
            </div>
          </div>
        </section>

        {/* Limitation of Liability */}
        <section>
          <h2 className="text-2xl font-semibold text-gray-900 mb-4">7. Limitation of Liability</h2>
          <div className="border border-gray-200 rounded-lg p-6">
            <p className="text-gray-700 mb-4">MINUUTES shall not be liable for:</p>
            <ul className="list-disc pl-6 space-y-2 text-gray-700">
              <li>Quality of consulting services provided by independent consultants</li>
              <li>Technical issues beyond our reasonable control</li>
              <li>Indirect or consequential losses</li>
              <li>Loss of data or business interruption</li>
            </ul>
          </div>
        </section>

        {/* Termination */}
        <section>
          <h2 className="text-2xl font-semibold text-gray-900 mb-4">8. Termination</h2>
          <div className="bg-red-50 border-l-4 border-red-500 p-4">
            <p className="text-gray-700">
              MINUUTES reserves the right to terminate or suspend access to our services immediately, without prior notice or liability, for
              any reason whatsoever, including breach of Terms.
            </p>
          </div>
        </section>

        {/* Governing Law */}
        <section>
          <h2 className="text-2xl font-semibold text-gray-900 mb-4">9. Governing Law</h2>
          <div className="bg-gray-50 p-6 rounded-lg">
            <p className="text-gray-700">
              These terms shall be governed by and construed in accordance with the laws of India, and any disputes will be subject to the
              exclusive jurisdiction of the courts in Patna, Bihar.
            </p>
          </div>
        </section>

        {/* Contact Information */}
        <section>
          <h2 className="text-2xl font-semibold text-gray-900 mb-4">10. Contact Information</h2>
          <div className="bg-gray-50 p-6 rounded-lg">
            <p className="text-gray-700 mb-4">For any questions regarding these Terms and Conditions, please contact us at:</p>
            <div className="space-y-3">
              <div className="flex items-center">
                <svg className="h-5 w-5 text-gray-500 mr-3" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
                  />
                </svg>
                <span className="text-gray-700">business@minuutes.com</span>
              </div>
              <div className="flex items-center">
                <svg className="h-5 w-5 text-gray-500 mr-3" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"
                  />
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 11a3 3 0 11-6 0 3 3 0 016 0z" />
                </svg>
                <span className="text-gray-700">
                  Ingena Technology Private Limited
                  <br />
                  Patna, Bihar, India
                </span>
              </div>
            </div>
          </div>
        </section>

        {/* Footer Note */}
        <div className="mt-12 pt-8 border-t border-gray-200">
          <p className="text-sm text-gray-500 text-center">
            These terms and conditions are subject to change. Last updated: November 24, 2024
          </p>
        </div>
      </div>
    </div>
  );
};

export default TermsAndConditions;
