import React from "react";
import { Mail, Phone, MapPin, Clock, Globe } from "lucide-react";

const ContactUs = () => {
  return (
    <div className="max-w-7xl mx-auto px-4 py-8">
      {/* Header */}
      <div className="text-center mb-12">
        <h1 className="text-4xl font-bold text-gray-900 mb-4">Contact Us</h1>
        <p className="text-lg text-gray-600 max-w-2xl mx-auto">
          Have questions about MINUUTES? Our team is here to help. Get in touch with us through any of these channels.
        </p>
      </div>

      {/* Main Content */}
      <div className="grid grid-cols-3 md:grid-cols-3 gap-12">
        {/* Contact Information */}
        <div className="space-y-8">
          <div>
            <h2 className="text-2xl font-semibold text-gray-900 mb-6">Get in Touch</h2>

            {/* Contact Cards */}
            <div className="space-y-4">
              <div className="bg-white p-6 rounded-lg shadow-sm border border-gray-200">
                <div className="flex items-start">
                  <Mail className="h-6 w-6 text-blue-600 mt-1" />
                  <div className="ml-4">
                    <p className="text-gray-600 mt-2">For support:</p>
                    <a href="mailto:support@minuutes.com" className="text-blue-600 hover:text-blue-700 mt-1 block">
                      business@minuutes.com
                    </a>
                  </div>
                </div>
              </div>

              <div className="bg-white p-6 rounded-lg shadow-sm border border-gray-200">
                <div className="flex items-start">
                  <Phone className="h-6 w-6 text-blue-600 mt-1" />
                  <div className="ml-4">
                    <h3 className="font-semibold text-gray-900">Call Us</h3>
                    <p className="text-gray-600 mt-1">Customer Support:</p>
                    <a href="tel:+918789377470" className="text-blue-600 hover:text-blue-700 mt-1 block">
                      +91 8789377470
                    </a>
                  </div>
                </div>
              </div>

              <div className="bg-white p-6 rounded-lg shadow-sm border border-gray-200">
                <div className="flex items-start">
                  <MapPin className="h-6 w-6 text-blue-600 mt-1" />
                  <div className="ml-4">
                    <h3 className="font-semibold text-gray-900">Visit Us</h3>
                    <p className="text-gray-600 mt-1">
                      Ingena Technology Private Limited
                      <br />
                      Patna, Bihar
                      <br />
                      India
                    </p>
                  </div>
                </div>
              </div>

              <div className="bg-white p-6 rounded-lg shadow-sm border border-gray-200">
                <div className="flex items-start">
                  <Clock className="h-6 w-6 text-blue-600 mt-1" />
                  <div className="ml-4">
                    <h3 className="font-semibold text-gray-900">Business Hours</h3>
                    <p className="text-gray-600 mt-1">
                      Monday - Friday: 9:00 AM - 6:00 PM (IST)
                      <br />
                      Saturday: 9:00 AM - 2:00 PM (IST)
                      <br />
                      Sunday: Closed
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* FAQ Section */}
      <div className="mt-16">
        <h2 className="text-2xl font-semibold text-gray-900 mb-6 text-center">Frequently Asked Questions</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6 max-w-4xl mx-auto">
          <div className="bg-white p-6 rounded-lg shadow-sm border border-gray-200">
            <h3 className="font-semibold text-gray-900 mb-2">How quickly can I expect a response?</h3>
            <p className="text-gray-600">We typically respond to all inquiries within 24 hours during business days.</p>
          </div>
          <div className="bg-white p-6 rounded-lg shadow-sm border border-gray-200">
            <h3 className="font-semibold text-gray-900 mb-2">Do you offer urgent support?</h3>
            <p className="text-gray-600">Yes, premium customers have access to priority support with faster response times.</p>
          </div>
          <div className="bg-white p-6 rounded-lg shadow-sm border border-gray-200">
            <h3 className="font-semibold text-gray-900 mb-2">Can I schedule a demo?</h3>
            <p className="text-gray-600">Absolutely! You can schedule a product demo through our booking system.</p>
          </div>
          <div className="bg-white p-6 rounded-lg shadow-sm border border-gray-200">
            <h3 className="font-semibold text-gray-900 mb-2">Where are you located?</h3>
            <p className="text-gray-600">Our headquarters is in Patna, India, but we serve customers globally.</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
