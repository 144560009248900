import React from "react";

const CancellationRefundPolicy = () => {
  return (
    <div className="max-w-4xl mx-auto px-4 py-8 bg-white">
      {/* Header */}
      <div className="mb-8 text-center border-b border-gray-200 pb-8">
        <h1 className="text-4xl font-bold text-gray-900 mb-2">Cancellation & Refund Policy</h1>
        <p className="text-gray-600">Ingena Technology Private Limited (Operating as MINUUTES)</p>
        <p className="text-sm text-gray-500 mt-2">Last Updated: November 24, 2024</p>
      </div>

      {/* Introduction */}
      <section>
        <h2 className="text-2xl font-semibold text-gray-900 mb-4">1. Introduction</h2>
        <div className="bg-blue-50 border-l-4 border-blue-500 p-4">
          <p className="text-blue-700">
            This policy outlines the terms and conditions for cancellations and refunds for consulting services booked through the MINUUTES
            platform.
          </p>
        </div>
      </section>

      {/* Cancellation Policy */}
      <section className="mt-8">
        <h2 className="text-2xl font-semibold text-gray-900 mb-4">2. Cancellation Policy</h2>
        <div className="space-y-4">
          <div className="border border-gray-200 rounded-lg p-4">
            <h3 className="font-semibold text-gray-900 mb-2">2.1 Client Cancellations</h3>
            <div className="space-y-4">
              <div className="bg-gray-50 p-4 rounded-lg">
                <p className="font-medium text-gray-900">24+ Hours Notice</p>
                <p className="text-gray-700">Full refund minus processing fees</p>
              </div>
              <div className="bg-gray-50 p-4 rounded-lg">
                <p className="font-medium text-gray-900">12-24 Hours Notice</p>
                <p className="text-gray-700">50% refund of consulting fee</p>
              </div>
              <div className="bg-gray-50 p-4 rounded-lg">
                <p className="font-medium text-gray-900">Less than 12 Hours Notice</p>
                <p className="text-gray-700">No refund</p>
              </div>
            </div>
          </div>

          <div className="border border-gray-200 rounded-lg p-4">
            <h3 className="font-semibold text-gray-900 mb-2">2.2 Consultant Cancellations</h3>
            <ul className="list-disc pl-6 space-y-2 text-gray-700">
              <li>Full refund to client</li>
              <li>Option to reschedule with same consultant</li>
              <li>Priority booking with alternative consultant</li>
            </ul>
          </div>
        </div>
      </section>

      {/* Refund Policy */}
      <section className="mt-8">
        <h2 className="text-2xl font-semibold text-gray-900 mb-4">3. Refund Policy</h2>
        <div className="space-y-4">
          <div className="border border-gray-200 rounded-lg p-4">
            <h3 className="font-semibold text-gray-900 mb-2">3.1 Eligible Refund Scenarios</h3>
            <ul className="list-disc pl-6 space-y-2 text-gray-700">
              <li>Technical issues preventing consultation completion</li>
              <li>Consultant no-show</li>
              <li>Service not matching described expertise</li>
              <li>Platform downtime affecting service delivery</li>
            </ul>
          </div>

          <div className="border border-gray-200 rounded-lg p-4">
            <h3 className="font-semibold text-gray-900 mb-2">3.2 Refund Process</h3>
            <div className="space-y-4">
              <div className="bg-gray-50 p-4 rounded-lg">
                <h4 className="font-medium text-gray-900 mb-2">Standard Refund Timeline</h4>
                <ul className="list-disc pl-6 space-y-2 text-gray-700">
                  <li>Processing time: 5-7 business days</li>
                  <li>Original payment method will be refunded</li>
                  <li>Email confirmation upon refund initiation</li>
                </ul>
              </div>

              <div className="bg-gray-50 p-4 rounded-lg">
                <h4 className="font-medium text-gray-900 mb-2">Required Information</h4>
                <ul className="list-disc pl-6 space-y-2 text-gray-700">
                  <li>Booking reference number</li>
                  <li>Reason for refund request</li>
                  <li>Transaction details</li>
                  <li>Supporting documentation (if applicable)</li>
                </ul>
              </div>
            </div>
          </div>

          <div className="border border-gray-200 rounded-lg p-4">
            <h3 className="font-semibold text-gray-900 mb-2">3.3 Non-Refundable Items</h3>
            <ul className="list-disc pl-6 space-y-2 text-gray-700">
              <li>Processing fees for cancellations</li>
              <li>Completed consultations</li>
              <li>No-show by client</li>
              <li>Late cancellations (less than 12 hours notice)</li>
            </ul>
          </div>
        </div>
      </section>

      {/* Technical Issues */}
      <section className="mt-8">
        <h2 className="text-2xl font-semibold text-gray-900 mb-4">4. Technical Issues</h2>
        <div className="bg-yellow-50 border-l-4 border-yellow-500 p-4 mb-4">
          <p className="text-gray-700">In case of technical difficulties during a consultation:</p>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <div className="bg-gray-50 p-4 rounded-lg">
            <h3 className="font-semibold text-gray-900 mb-2">Platform Issues</h3>
            <ul className="list-disc pl-6 space-y-2 text-gray-700">
              <li>Full refund or rescheduling option</li>
              <li>Priority support assistance</li>
              <li>Compensation for extended downtime</li>
            </ul>
          </div>
          <div className="bg-gray-50 p-4 rounded-lg">
            <h3 className="font-semibold text-gray-900 mb-2">User-Side Issues</h3>
            <ul className="list-disc pl-6 space-y-2 text-gray-700">
              <li>One-time courtesy reschedule</li>
              <li>Technical support guidance</li>
              <li>Partial refund consideration</li>
            </ul>
          </div>
        </div>
      </section>

      {/* Dispute Resolution */}
      <section className="mt-8">
        <h2 className="text-2xl font-semibold text-gray-900 mb-4">5. Dispute Resolution</h2>
        <div className="border border-gray-200 rounded-lg p-6 space-y-4">
          <p className="text-gray-700">For disputed transactions:</p>
          <div className="space-y-4">
            <div className="bg-gray-50 p-4 rounded-lg">
              <h3 className="font-semibold text-gray-900 mb-2">Review Process</h3>
              <ul className="list-disc pl-6 space-y-2 text-gray-700">
                <li>Submit detailed complaint</li>
                <li>Documentation review</li>
                <li>Fair resolution within 7 business days</li>
              </ul>
            </div>
            <div className="bg-gray-50 p-4 rounded-lg">
              <h3 className="font-semibold text-gray-900 mb-2">Resolution Options</h3>
              <ul className="list-disc pl-6 space-y-2 text-gray-700">
                <li>Full or partial refund</li>
                <li>Service credit</li>
                <li>Complimentary consultation</li>
              </ul>
            </div>
          </div>
        </div>
      </section>

      {/* Contact Information */}
      <section className="mt-8">
        <h2 className="text-2xl font-semibold text-gray-900 mb-4">6. Contact Information</h2>
        <div className="bg-gray-50 p-6 rounded-lg">
          <p className="text-gray-700 mb-4">For refund requests and cancellation support:</p>
          <div className="space-y-3">
            <div className="flex items-center">
              <svg className="h-5 w-5 text-gray-500 mr-3" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
                />
              </svg>
              <span className="text-gray-700">business@minuutes.com</span>
            </div>
            {/* <div className="flex items-center">
              <svg className="h-5 w-5 text-gray-500 mr-3" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z"
                />
              </svg>
              <span className="text-gray-700">+1 (555) 123-4567</span>
            </div> */}
          </div>
        </div>
      </section>

      {/* Footer */}
      <div className="mt-12 pt-8 border-t border-gray-200">
        <p className="text-sm text-gray-500 text-center">
          This cancellation & refund policy is subject to change. Last updated: November 24, 2024
        </p>
      </div>
    </div>
  );
};

export default CancellationRefundPolicy;
