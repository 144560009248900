import React from "react";

const ShippingPolicy = () => {
  return (
    <div className="max-w-4xl mx-auto px-4 py-8 bg-white">
      {/* Header Section */}
      <div className="mb-8 text-center border-b border-gray-200 pb-8">
        <h1 className="text-4xl font-bold text-gray-900 mb-2">Shipping Policy</h1>
        <p className="text-gray-600">Ingena Technology Private Limited (Operating as MINUUTES)</p>
        <p className="text-sm text-gray-500 mt-2">Last Updated: November 24, 2024</p>
      </div>

      {/* Main Content */}
      <div className="space-y-8">
        {/* Digital Services Section */}
        <section className="prose max-w-none">
          <h2 className="text-2xl font-semibold text-gray-900 mb-4">1. Digital Services Nature</h2>
          <div className="bg-blue-50 border-l-4 border-blue-500 p-4 mb-6">
            <p className="text-blue-700">
              MINUUTES primarily operates as a digital services platform. Our core services are delivered electronically through our
              consulting infrastructure, and therefore do not require physical shipping.
            </p>
          </div>
          <p className="text-gray-700 mb-4">Our services include but are not limited to:</p>
          <ul className="list-disc pl-6 space-y-2 text-gray-700">
            <li>Digital consulting session access</li>
            <li>Online scheduling system</li>
            <li>Digital payment processing</li>
            <li>Electronic delivery of consulting materials</li>
          </ul>
        </section>

        {/* Service Delivery Section */}
        <section className="prose max-w-none">
          <h2 className="text-2xl font-semibold text-gray-900 mb-4">2. Service Delivery</h2>
          <p className="text-gray-700 mb-4">All services are delivered through our digital platform with the following specifications:</p>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-6">
            <div className="bg-gray-50 p-4 rounded-lg">
              <h3 className="font-semibold text-gray-900 mb-2">Instant Access</h3>
              <p className="text-gray-600">Services are available immediately upon successful payment processing</p>
            </div>
            <div className="bg-gray-50 p-4 rounded-lg">
              <h3 className="font-semibold text-gray-900 mb-2">Global Availability</h3>
              <p className="text-gray-600">Available 24/7 subject to consultant availability</p>
            </div>
          </div>
        </section>

        {/* Terms & Conditions Section */}
        <section className="prose max-w-none">
          <h2 className="text-2xl font-semibold text-gray-900 mb-4">3. Terms & Conditions</h2>
          <div className="bg-gray-50 p-6 rounded-lg">
            <h3 className="font-semibold text-gray-900 mb-3">Service Guarantee</h3>
            <ul className="space-y-3 text-gray-700">
              <li className="flex items-start">
                <svg className="h-6 w-6 text-green-500 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                </svg>
                <span>99.9% platform uptime guarantee</span>
              </li>
              <li className="flex items-start">
                <svg className="h-6 w-6 text-green-500 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                </svg>
                <span>Instant service activation</span>
              </li>
              <li className="flex items-start">
                <svg className="h-6 w-6 text-green-500 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                </svg>
                <span>24/7 technical support</span>
              </li>
            </ul>
          </div>
        </section>

        {/* Refund Policy Section */}
        <section className="prose max-w-none">
          <h2 className="text-2xl font-semibold text-gray-900 mb-4">4. Refund Policy</h2>
          <div className="border border-gray-200 rounded-lg p-6">
            <p className="text-gray-700 mb-4">Our refund policy for digital services:</p>
            <ul className="space-y-3 text-gray-700">
              <li className="flex items-start">
                <svg className="h-6 w-6 text-blue-500 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                  />
                </svg>
                <span>Unused consulting sessions are eligible for full refund within 24 hours of purchase</span>
              </li>
              <li className="flex items-start">
                <svg className="h-6 w-6 text-blue-500 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                  />
                </svg>
                <span>Technical issues resulting in service interruption qualify for service credit or refund</span>
              </li>
            </ul>
          </div>
        </section>

        {/* Contact Information */}
        <section className="prose max-w-none">
          <h2 className="text-2xl font-semibold text-gray-900 mb-4">5. Contact Information</h2>
          <div className="bg-gray-50 p-6 rounded-lg">
            <p className="text-gray-700 mb-4">For any questions regarding our shipping policy or digital service delivery:</p>
            <div className="space-y-3">
              <div className="flex items-center">
                <svg className="h-5 w-5 text-gray-500 mr-3" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
                  />
                </svg>
                <span className="text-gray-700">business@minuutes.com</span>
              </div>
            </div>
          </div>
        </section>

        {/* Footer Note */}
        <div className="mt-12 pt-8 border-t border-gray-200">
          <p className="text-sm text-gray-500 text-center">This shipping policy is subject to change. Last updated: November 24, 2024</p>
        </div>
      </div>
    </div>
  );
};

export default ShippingPolicy;
