import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import axios from "axios";
import smoothscroll from "smoothscroll-polyfill";
import ReactGA from "react-ga";
// import api from "./axiosConfig";
import api from "./axiosConfig";
// Import your components here
import LandingPage from "./components/LandingPage";
import ShippingPolicy from "./components/ShippingPolicy";
import TermsAndConditions from "./components/TermsAndCondition";
import PrivacyPolicy from "./components/PrivacyPolicy";
import CancellationRefundPolicy from "./components/Cancellation";
import ContactUs from "./components/ContactUs";
const App = () => {
  const [isRestricted, setIsRestricted] = useState(false);
  const [clientIP, setClientIP] = useState(null);
  const ipInfoToken = "54225501c526ff"; // Replace with your ipinfo.io token
  const myIP = "202.142.86.144"; // Replace with your actual IP address
  // const myIP = "202.142.76.789"; // Replace with your actual IP address

  useEffect(() => {
    // Kick off smooth scroll polyfill
    smoothscroll.polyfill();

    // Fetch client IP using ipinfo.io
    axios
      .get(`https://ipinfo.io/json?token=${ipInfoToken}`)
      .then((response) => {
        const ip = response.data.ip;
        setClientIP(ip);
        // console.log("Fetched IP from ipinfo.io:", ip);

        // Skip restriction check if IP matches your IP
        if (ip === myIP) {
          // console.log("IP matches myIP, skipping VPN/proxy check.");
          return;
        }

        // Send IP to backend for VPN/Proxy check
        // console.log("Sending IP to backend for VPN/proxy check:", ip);
        return api.post("api/check-vpn-proxy/", { ip });
      })
      .then((response) => {
        if (response) {
          // Ensure response is valid (skipped if IP matches myIP)
          const { is_vpn, country } = response.data;
          // console.log("Received VPN/proxy check response:", { is_vpn, country });

          // const restrictedCountries = ["NG", "PK", "VN", "BD", "TZ", "IR", "KP", "IN"];
          const restrictedCountries = ["NG", "PK", "VN", "BD", "TZ", "IR", "KP"];

          // Set restriction status based on VPN/proxy status or restricted country
          if (is_vpn || restrictedCountries.includes(country)) {
            setIsRestricted(true);
            console.log("Access restricted based on VPN/proxy or country.");
          } else {
            console.log("No restrictions for this IP.");
          }
        }
      })
      .catch((error) => {
        console.error("Error fetching IP information or checking VPN:", error);
      });

    // Track page view on component mount
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  if (isRestricted) {
    return <div>Unfortunately, this service/website is not available in your country.</div>;
  }

  return (
    <Router>
      <Routes>
        <Route path="" element={<LandingPage />} />
        <Route path="/shipping/" element={<ShippingPolicy />} />
        <Route path="/terms/" element={<TermsAndConditions />} />
        <Route path="/privacy/" element={<PrivacyPolicy />} />
        <Route path="/refund/" element={<CancellationRefundPolicy />} />
        <Route path="/contact/" element={<ContactUs />} />
        {/* <Route path="/signin/" element={<SignIn />} />
        <Route path="/signup/" element={<SignUp />} />
        <Route path="/forgot-password/" element={<ForgotPassword />} />
        <Route path="/reset-password/:uidb64/:token" element={<ResetPassword />} />
        <Route path="/full-report/:reportId" element={<Report />} />
        <Route path="/mini-report/:reportId" element={<MiniReport />} />
        <Route path="/dashboard/" element={<ReportDashboard />} />
        <Route path="/previewx/" element={<ReportDemo />} />
        <Route path="/minipreview/" element={<MiniReportDemo />} />
        <Route path="/feedback" element={<FeedbackAssistant />} />
        <Route path="/credits/" element={<Credits />} />
        <Route path="/report/" element={<ReportDashboard />} />
        <Route path="/privacy/" element={<Privacy />} />
        <Route path="/terms/" element={<Terms />} />
        <Route path="/dooropeners/" element={<DoorOpeners />} /> */}{" "}
        {/* <Route path="/minuutes/" element={<Minuutes />} /> */}
      </Routes>
    </Router>
  );
};

export default App;
